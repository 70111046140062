import { handleActions } from "redux-actions";

import actions from "../actionCreators";
import initialState from "../initial.state";

const data = handleActions(
  {
    [actions.setExercisesQuery]: (state, { payload: { exercisesQuery } }) => ({
      ...state,
      exercisesQuery,
    }),
    [actions.setExercisesLang]: (state, { payload: { exercisesLang } }) => ({
      ...state,
      exercisesLang,
    }),
    [actions.addExercisesToQueue]: (state, { payload: { exercisesIds } }) => ({
      ...state,
      exercisesIds,
    }),
    [actions.clearExercisesQueue]: (state) => ({
      ...state,
      exercisesIds: [],
      exercisesById: {},
    }),
    [actions.advanceExercisesQueue]: (
      state,
      { payload: { requeueCurrentExercise } }
    ) => {
      const exercisesIds = state.exercisesIds.slice(1);
      const currentExercise = { ...state.exercisesById[state.exercisesIds[0]] };

      if (requeueCurrentExercise) {
        exercisesIds.push(state.exercisesIds[0]);
        currentExercise.retried = true;
      }

      return {
        ...state,
        exercisesIds,
        exercisesById: {
          ...state.exercisesById,
          [currentExercise.id]: currentExercise,
        },
      };
    },
    [actions.addExercise]: (state, { payload: { exercise } }) => ({
      ...state,
      exercisesById: {
        ...state.exercisesById,
        [exercise.id]: exercise,
      },
    }),
    [actions.setBlockId]: (state, { payload: { blockId } }) => ({
      ...state,
      blockId,
    }),
  },
  initialState.data || {}
);

export default data;
