import { ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";

import { Auth } from "infra";
import { urlUtils } from "utils";

import config from "../config";

const httpLink = createHttpLink({
  uri: urlUtils.getHostFromUrl().split(".").includes("astex")
    ? config.ASTEX_READ_SERVER_URL
    : config.READ_SERVER_URL,
});

const extractToken = (idToken) =>
  idToken && idToken.jwtToken ? `Bearer ${idToken.jwtToken}` : "";

export const refreshSession = async () => {
  try {
    const cognitoUser = await Auth.currentAuthenticatedUser();
    const currentSession = await Auth.currentSession();
    const session = await new Promise((resolve) =>
      cognitoUser.refreshSession(
        currentSession.refreshToken,
        (err, session) => {
          resolve(session);
        }
      )
    );
    console.log("session", session);
    const { idToken = {} } = await Auth.currentSession();
    console.log("idToken", idToken);
    if (window) {
      window.location = "";
    }
  } catch (e) {
    console.log("Unable to refresh Token", e);
  }
};

const authLink = setContext(async (_, { headers }) => {
  const { idToken = {} } = await Auth.currentSession();

  return {
    headers: {
      ...headers,
      authorization: extractToken(idToken),
    },
  };
});

export const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache(),
});
