import { createActions } from "redux-actions";

import types from "./actionTypes";

const { app } = createActions({
  [types.init$]: ({ history }) => ({ history }),
  [types.finish$]: ({ sendStats, stats }) => ({ sendStats, stats }),
});

export default app;
