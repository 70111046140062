const initialState = {
  ui: {
    loading: false,
  },
  data: {
    blockId: null,
    exercisesQuery: {},
    exercisesLang: "en",
    exercisesIds: [],
    exercisesById: {},
  },
};

export default initialState;
