import { createActions } from "redux-actions";

import types from "./actionTypes";

const { player } = createActions({
  [types.SET_LOADING]: ({ loading }) => ({ loading }),
  [types.SET_EXERCISES_QUERY]: ({ exercisesQuery }) => ({ exercisesQuery }),
  [types.SET_EXERCISES_LANG]: ({ exercisesLang }) => ({ exercisesLang }),
  [types.ADD_EXERCISES_TO_QUEUE]: ({ exercisesIds }) => ({ exercisesIds }),
  [types.GO_TO_NEXT_EXERCISE$]: ({ requeueCurrentExercise, history }) => ({
    requeueCurrentExercise,
    history,
  }),
  [types.ADVANCE_EXERCISES_QUEUE]: ({ requeueCurrentExercise }) => ({
    requeueCurrentExercise,
  }),
  [types.GET_EXERCISE$]: ({ exerciseId }) => ({ exerciseId }),
  [types.ADD_EXERCISE]: ({ exercise }) => ({ exercise }),
  [types.CLEAR_EXERCISES_QUEUE]: () => ({}),
  [types.SET_BLOCK_ID]: ({ blockId }) => ({ blockId }),
  [types.CREATE_EXERCISE_LOG$]: ({ exercise, answer, exerciseStartTime }) => ({
    exercise,
    answer,
    exerciseStartTime,
  }),
});

export default player;
