import { takeLatest, put, call, select } from "@redux-saga/core/effects";
import merge from "lodash.merge";

import { commonUtils, urlUtils } from "utils";
import { Auth } from "infra";

import api from "api";
import getClientByName from "clients";

import { actions as uiActions } from "state/ui";
import {
  actions as sessionActions,
  selectors as sessionSelectors,
} from "state/session";
import {
  actions as playerActions,
  selectors as playerSelectors,
} from "state/player";
import { actions as authActions, selectors as authSelectors } from "state/auth";
import { types as appActionTypes } from "state/app";

import defaultTheme from "components/defaultTheme";

import { exercisesApi } from "state/player/api";

function* extractAndStoreClientNameFromUrl() {
  const clientName = urlUtils.getURLQueryVariable("client");
  yield put(sessionActions.setClient({ client: clientName }));

  return clientName;
}

function* getClient() {
  let clientName;

  clientName = yield select(sessionSelectors.getClient);

  if (commonUtils.isEmpty(clientName)) {
    clientName = yield call(extractAndStoreClientNameFromUrl);
  }

  return getClientByName(clientName);
}

function* setTheme({ theme, client }) {
  const appliedTheme = merge(defaultTheme, client.themes[theme]);
  yield put(sessionActions.setTheme({ theme: appliedTheme }));
}

function* extractAndStoreTokenData({ client, token }) {
  const tokenData = client.session.extractDataFromSessionToken(token);

  yield call(setTheme, { theme: tokenData.theme, client });

  yield put(sessionActions.setBackUrl({ backUrl: tokenData.back_url }));
  yield put(sessionActions.setWebhooks({ webhooks: tokenData.webhooks }));
  yield put(
    playerActions.setExercisesQuery({
      exercisesQuery: tokenData.exercises_query,
    })
  );
  yield put(
    playerActions.setExercisesLang({ exercisesLang: tokenData.exercises_lang })
  );
  yield put(authActions.setUser({ user: tokenData.user_id }));

  return tokenData;
}

function* getTokenData({ client }) {
  let token;
  const urlToken = urlUtils.getURLQueryVariable("token");
  const stateToken = yield select(authSelectors.getToken);

  if (urlToken !== stateToken || commonUtils.isEmpty(stateToken)) {
    token = urlToken;
  } else {
    token = stateToken;
  }

  yield put(authActions.setToken({ token }));

  return yield call(extractAndStoreTokenData, { client, token });
}

async function getUserOrCreate({ username, clientName, token }) {
  let user = null;
  let crendentials = { username: username, password: "1234567890.!&%" };

  // try to create user
  try {
    const _user = await Auth.signUp({
      username: crendentials.username,
      email: crendentials.username,
      password: crendentials.password,
      name: crendentials.username,
      attributes: {
        "custom:terms": "true",
        "custom:communications": "true",
        "custom:username": crendentials.username,
      },
    });

    user = _user.user;
  } catch (error) {
    if (error.code === "UsernameExistsException") {
      // get temporal password to login
      const credentialsResponse = await api().generateLoginCredentials({
        username,
        client: clientName,
        token,
      });
      crendentials = await credentialsResponse.json();
    }
  } finally {
    // login
    user = await Auth.signIn(crendentials.username, crendentials.password);
  }

  return user;
}

function* initializePlayer() {
  const generateBlockId = () =>
    `${Math.ceil(Math.random() * 1000)}_${new Date().getTime()}`;
  const exercises = yield select(playerSelectors.getExercisesIds);
  const blockId = generateBlockId();
  yield put(playerActions.setBlockId({ blockId }));
  const exercisesLanguage = yield select(playerSelectors.getExercisesLang);

  if (commonUtils.isEmpty(exercises)) {
    const randomExercisesResponse = yield call(
      exercisesApi.getRandomExercises,
      { numberOfExercises: 6, exercisesLanguage }
    );
    const exercisesIds = randomExercisesResponse.map(
      (randomExercise) => randomExercise.id
    );
    yield put(playerActions.addExercisesToQueue({ exercisesIds }));
  }
}

function* handleInitApp({ payload: { history } }) {
  try {
    yield put(uiActions.setLoading({ loading: true }));

    const client = yield call(getClient);
    const tokenData = yield call(getTokenData, { client });
    const token = yield select(authSelectors.getToken);

    const wannalisnUser = yield call(getUserOrCreate, {
      username: tokenData.user_id,
      clientName: client.constants.clientName,
      token,
    });
    if (!!wannalisnUser) {
      yield put(authActions.setLoggedIn({ loggedIn: true }));
      yield call(initializePlayer);
      yield call(history.push, "/exercises");
    } else {
      yield console.error("Error with Wannalisn auth");
    }
  } catch (error) {
    // NO auth => 403
  } finally {
    yield put(uiActions.setLoading({ loading: false }));
  }
}

async function sendProviderActivity({ providerActivityUrl, stats, user }) {
  const activityResponse = await api().post({
    path: "provider/activity",
    body: {
      providerActivityUrl,
      stats,
      user,
    },
  });

  return activityResponse;
}

/*
function* handleCloseApp({ payload: { stats } }) {
  const user = yield select(authSelectors.getUser);
  const providerActivityUrl = yield select(sessionSelectors.getWebhooks)
    ?.activity;
  try {
    yield call(sendProviderActivity, {
      stats,
      user: user.split("@")[0],
      providerActivityUrl,
    });
  } catch (error) {
    // nothing to do
  } finally {
    const backUrl = yield select(sessionSelectors.getBackUrl);
    yield put({ type: "RESET_APP", payload: {} });
    window.location = backUrl;
  }
}*/

function* handleFinishApp({ payload: { stats, sendStats } }) {
  const user = yield select(authSelectors.getUser);
  const providerActivityUrl = yield select(sessionSelectors.getWebhooks)
    ?.activity;
  try {
    if (sendStats) {
      yield call(sendProviderActivity, {
        stats,
        user: user.split("@")[0],
        providerActivityUrl,
      });
    }
  } catch (error) {
    // nothing to do
  } finally {
    const backUrl = yield select(sessionSelectors.getBackUrl);
    yield put({ type: "RESET_APP", payload: {} });
    window.location = backUrl;
  }
}

export default function* saga() {
  yield takeLatest(appActionTypes.init$, handleInitApp);
  yield takeLatest(appActionTypes.finish$, handleFinishApp);
}
