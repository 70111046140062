const getStateUI = (state) => state?.player?.ui;
const getStateData = (state) => state?.player?.data;
const getExercisesById = (state) => getStateData(state).exercisesById;

const isLoading = (state) => getStateUI(state)?.loading;
const getExercisesIds = (state) => getStateData(state).exercisesIds;
const getCurrentExerciseId = (state) => getExercisesIds(state)[0];
const getExerciseById = (exerciseId) => (state) =>
  getExercisesById(state)[exerciseId];
const getBlockId = (state) => getStateData(state).blockId;
const getExercisesLang = (state) => getStateData(state).exercisesLang;
const getCompletedProgressPercentage = (state) =>
  Math.ceil(((6 - getStateData(state).exercisesIds.length) / 6) * 100);

const playerSelectors = {
  isLoading,
  getExercisesIds,
  getExerciseById,
  getCurrentExerciseId,
  getBlockId,
  getExercisesLang,
  getCompletedProgressPercentage,
};
export default playerSelectors;
