import { gql } from "apollo-boost";
import { client } from "apollo/client";

const GET_EXERCISE_BY_ID = gql`
  query getExerciseById($exerciseId: uuid!) {
    exercises_by_pk(id: $exerciseId) {
      name
      title
      subtitle
      answer
      assets(order_by: { position: asc }) {
        asset {
          url
          url_full
          url_low
          asset_type {
            human_id
          }
        }
        position
        asset_function {
          human_id
        }
      }
      modal_fail_info
      modal_fail_title
      modal_fail_image
      modal_fail_has_image
      modal_correct_title
      modal_correct_info
      modal_correct_image
      modal_correct_has_image
      type
      info
      answers_options
      id
      language
    }

    exercises_with_difficulty_dosis(
      where: { exercise_id: { _eq: $exerciseId } }
      order_by: { difficulty: desc }
    ) {
      difficulty
    }
  }
`;

// Exclude SPEAK exercises for the moment
const GET_RANDOM_EXERCISES_IDS = gql`
  query getRandomExercisesIds(
    $numberOfExercises: Int!
    $offset: Int!
    $exercisesLanguage: String!
  ) {
    exercises(
      limit: $numberOfExercises
      offset: $offset
      where: {
        type: { _neq: "SPEAK" }
        language: { _eq: $exercisesLanguage }
        is_ready: { _eq: true }
      }
      order_by: { id: desc }
    ) {
      id
    }
  }
`;

const OFFSETS_BY_LANG = {
  en: 3500,
  es: 350,
  it: 690,
  //pt: 100,
  //tk: 100,
};

const exercisesApi = {
  getExerciseById: async ({ exerciseId }) => {
    const { data } = await client.query({
      query: GET_EXERCISE_BY_ID,
      variables: { exerciseId },
      fetchPolicy: "no-cache",
    });

    return data?.exercises_by_pk || null;
  },
  getRandomExercises: async ({
    numberOfExercises,
    exercisesLanguage = "en",
  }) => {
    const language = OFFSETS_BY_LANG[exercisesLanguage]
      ? exercisesLanguage
      : "en";
    const AVAILABLE_OFFSETS_UNTIL_NOW = OFFSETS_BY_LANG[language];
    const offset = Date.now() % AVAILABLE_OFFSETS_UNTIL_NOW;
    const { data } = await client.query({
      query: GET_RANDOM_EXERCISES_IDS,
      variables: { numberOfExercises, offset, exercisesLanguage: language },
      fetchPolicy: "no-cache",
    });

    return data?.exercises || [];
  },
};

export default exercisesApi;
