import React, { useEffect } from "react";
import { useSelector } from "react-redux";

import {
  selectors as playerSelectors,
  hooks as playerHooks,
} from "state/player";

import {
  Exercise,
  ExercisesProgressBar,
  MediaAsset,
  Header,
  Loader,
} from "./styled";

import { ReactComponent as BackIcon } from "./back.svg";

import { useSendFeedbackAndClose } from "../Feedback/Feedback";
const BackButton = () => {
  const { finishExercisesBlock } = useSendFeedbackAndClose();
  return (
    <div
      style={{
        position: "absolute",
        left: "10px",
        top: "18px",
        cursor: "pointer",
        zIndex: 100,
      }}
    >
      <button
        onClick={() => finishExercisesBlock({ sendStands: false })}
        style={{
          background: "transparent",
          border: "none",
          cursor: "pointer",
          fontSize: "1.5em",
        }}
      >
        <BackIcon />
      </button>
    </div>
  );
};

const Player = ({ className }) => {
  const currentExerciseId = useSelector(playerSelectors.getCurrentExerciseId);
  const completedProgressPercentage = useSelector(
    playerSelectors.getCompletedProgressPercentage
  );
  const { exercise, getExercise } =
    playerHooks.useGetExerciseById(currentExerciseId);

  useEffect(() => {
    if (!!currentExerciseId && !exercise) {
      getExercise();
    }
  }, [exercise, getExercise, currentExerciseId]);

  return (
    <>
      <BackButton />
      <ExercisesProgressBar completedProgress={completedProgressPercentage} />
      <div className={className}>
        <Header />
        {exercise ? (
          <>
            <MediaAsset exercise={exercise} />
            <Exercise exercise={exercise} key={exercise.id} />
          </>
        ) : (
          <Loader />
        )}
      </div>
    </>
  );
};

export default Player;
